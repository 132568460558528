<!-- 小样商品列表 -->
<template>
  <div>
    <div class="page-head">
      <h3>
        样品申领规则：{{
          sampleLimitNum > 0 ? `每人限领${sampleLimitNum}次` : '不限次数'
        }}，每次只能申请领用一件；
      </h3>
      <el-button @click="dialogVisibleglimg = true">查看政策详细攻略</el-button>
    </div>
    <el-table
      :data="tableData"
      ref="multipleTable"
      style="width: 100%"
      tooltip-effect="dark"
      align="center"
      v-loading="tableLoading"
    >
      <el-table-column label="样品消息" width="330px" align="center">
        <template slot-scope="scope">
          <div class="goods-info">
            <div class="cell cell-flex">
              <router-link
                :to="
                  '/syoung/commodity/goods-detail/' +
                  scope.row.commodityId +
                  '?sampleId=' +
                  scope.row.id
                "
              >
                <div class="img-goods-box">
                  <oss-image :src="scope.row.imgUrl" class="table-img" />
                  <span v-if="scope.row.type === 'GLOBAL'" class="img-goods-tag">海淘</span>
                </div>
              </router-link>
              <div class="cell-flex-column">
                <div>
                  <div class="name">
                    <router-link
                      :to="
                        '/syoung/commodity/goods-detail/' +
                        scope.row.commodityId +
                        '?sampleId=' +
                        scope.row.id
                      "
                      >{{ scope.row.name }}</router-link
                    >
                  </div>
                </div>
                <div class="typeName">
                  <div>
                    建议零售价:
                    <span>￥{{ scope.row.retailPrice }}</span>
                  </div>
                  <div>
                    活动大促价:
                    <span>￥{{ scope.row.minControlPrice }}</span>
                  </div>
                  <div>
                    预估批发价:
                    <span>￥{{ scope.row.wholesalePrice }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="样品领用价" width="auto" align="center">
        <template slot-scope="scope">{{ scope.row.price }}</template>
      </el-table-column>
      <el-table-column
        label="库存"
        prop="stockQuantity"
        width="auto"
        align="center"
      ></el-table-column>
      <el-table-column label="领取数量" width="auto" align="center">
        <template slot-scope="scope">1</template>
      </el-table-column>
      <el-table-column label="领取条件" width="auto" align="center">
        <template slot-scope="scope">{{
          scope.row.isLimitNew === '1' ? '仅限新用户' : '所有用户'
        }}</template>
      </el-table-column>
      <el-table-column label="操作" width="auto" align="center">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="primary"
            :disabled="scope.row.isEnable === '0' || scope.row.isCanApply !== '1'"
            @click="settlementNormal(scope.row)"
            >申请试用</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageNo"
        :page-size="pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        background
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    <el-dialog
      title="新人政策攻略"
      :visible.sync="dialogVisibleglimg"
      width="450px"
      :before-close="() => (dialogVisibleglimg = false)"
    >
      <img class="glimg" :src="glimg" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { sampleActivityMyListPage, getByType } from '@/api/sample-list';
export default {
  data() {
    return {
      sampleLimitNum: 0,
      tableLoading: false,
      pageNo: 1,
      pageSize: 10,
      listLoading: false,
      total: 0,
      dialogVisibleglimg: false,
      glimg:
        'https://oss.syounggroup.com/soyoung-zg/210113143436219778527500737.jpg?filename=%E6%96%B0%E4%BA%BA%E6%94%BF%E7%AD%961-13.jpg',
      tableData: [],
    };
  },
  filters: {},
  components: {},
  computed: {},
  created() {
    this.fetchData();
    this.getByType();
  },
  mounted() {},
  methods: {
    getByType() {
      getByType().then(res => {
        this.sampleLimitNum = res.data.typeValue * 1;
      });
    },
    // 商品类型
    settlementNormal(row) {
      const data = [{ ...row, sampleId: row.id, quantity: 1 }];
      sessionStorage.setItem('orderDetail', JSON.stringify(data));
      this.$router.push({
        path: '/syoung/checkout/list',
        query: {
          type: row.type,
          goodsType: 'sample',
          brandType: row.brandType
        },
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    fetchData() {
      this.tableLoading = true;
      const data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      sampleActivityMyListPage(data)
        .then(res => {
          const { list = [], total = 0 } = res.data;
          this.total = total;
          this.tableData = list;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
};
</script>
<style lang='scss' scoped>
.page-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  h3 {
    color: red;
  }
}
.cell-flex {
  display: flex;
}
.goods-info {
  .cell-flex-column {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      /*! autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      line-height: initial;
      transition: color 0.2s;
      cursor: pointer;
      margin-bottom: 4px;
      &:hover {
        color: rgb(64, 158, 255);
      }
    }
  }
  .img-goods-box {
    position: relative;
    width: 124px;
    height: 124px;
    border: 1px solid #f1f1f1;
    margin-right: 15px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 14px;
    .img-goods-tag {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 124px;
      height: 20px;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      line-height: 20px;
    }
  }
  .table-img {
    width: 100%;
    height: 100%;
  }
}
.glimg {
  width: 100%;
}
</style>
