import { withExtTenantIdRequest } from '@/utils/request';
// 小样分页
export function sampleActivityMyListPage(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/sampleActivity/myListPage`,
    method: 'post',
    data
  });
}

// 获取活动配置
export function getByType() {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/activityConfig/getByType?type=sampleLimitNum`,
    method: 'get',
  });
}
